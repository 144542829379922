import { useTheme } from "@material-ui/core";
import React from "react";
import { analyticsTableToolbar } from "../pages/Analytics/util";
import ActionTable from "./ActionTable";
import { ExportCsv, ExportPdf } from "@material-table/exporters";

export const defaultTableOptions = {
  search: false,
  padding: "dense",
  actionsColumnIndex: -1,
  exportAllData: true,
  exportMenu: [
    {
      label: "Export PDF",
      exportFunc: (cols, data) => ExportPdf(cols, data, "export"),
    },
    {
      label: "Export CSV",
      exportFunc: (cols, data) => ExportCsv(cols, data, "export"),
    },
  ],
};

export default ({ config, onEdit, onDelete }) => {
  const theme = useTheme();
  return (
    <ActionTable
      config={{
        ...config,
        components: analyticsTableToolbar(theme),
        options: defaultTableOptions,
      }}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

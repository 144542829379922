import { useMemo, useState } from "react";
import Chart from "./Chart";
import config from "../../constants/charts";
import { defaultChartOptions } from "../../helpers";
import { useMetricsState } from "../../context/metrics";

import { getChartConfig } from "../../helpers/getChartConfig";
import { useChartData } from "../../data/useChartData";
import {
  Button,
  Card,
  CardContent,
  Theme,
  Typography,
} from "@material-ui/core";
import Modal from "../elements/Modal";
import moment, { Moment } from "moment-timezone";
import {  AnnotationOptions } from "chartjs-plugin-annotation";

type NoteData = {
  note: string;
  timestamp: Moment;
};
const NotesModal = ({
  modalOpen,
  setModalOpen,
  data,
}: {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  data?: NoteData;
}) => {
  //remove onClick event and close modal
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal open={modalOpen}>
      <Card>
        <CardContent>
          <Typography variant="h5">View notes</Typography>
          <Typography color="textSecondary">
            {data?.timestamp.format("DD/MM/YY HH:mm")}
          </Typography>
          <br />
          <Typography>{data?.note}</Typography>
          <br />
          <br />
          <Button
            size="medium"
            variant="contained"
            onClick={() => closeModal()}
          >
            Close
          </Button>
        </CardContent>
      </Card>
    </Modal>
  );
};

const ellipsise = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength).trim() + "...";
};

const ChartAgronomics = ({
  chart,
  fieldId,
  range,
  theme,
  chartEvents,
}: {
  chart: { id: keyof typeof config; group: string };
  fieldId: number;
  range: unknown;
  theme: Theme;
  chartEvents: {
    chartRef: unknown;
  };
}) => {
  const { chartData } = useChartData(chart.group, range, fieldId) as {
    chartData?: Record<string, string>[];
  };

  const metrics = useMetricsState();

  let noteIdxToShow: number | undefined;
  const [noteToModalShow, setNoteToModalShow] = useState<NoteData>();
  const notes = useMemo(
    () =>
      chartData
        ?.filter((f) => f.notes)
        .map<NoteData>((f) => ({
          note: f.notes,
          timestamp: moment(f.timestamp),
        })) ?? [],
    [chartData]
  );

  const chartConfig = useMemo(() => {
    const initialConfig = getChartConfig(
      chartData ?? [],
      chart.id,
      theme,
      metrics
    );
    if (!initialConfig.datasets.length && notes.length){
      // add a dummy dataset, otherwise the chart will not render at all
      return {
        datasets: [
          {
            // if the label is empty the chart will also not render, but just a space works
            label: " ",
            data: [],
            borderColor: "rgba(0, 0, 0, 0)",
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        ],
      }
    }
    return {
      datasets: initialConfig.datasets.map((item) => {
        return {
          ...item,
          yAxisID:
            item.label.includes("Run-Off") || item.label.includes("Moisture")
              ? "y"
              : "y1",
        };
      }),
    };
  }, [chartData, theme, chart.id, metrics]);


  const chartOptions = useMemo(() => {
    const options = defaultChartOptions(
      config[chart.id].axis,
      config[chart.id].legend,
      range,
      chartEvents
    );

    const annotationSettings = Object.fromEntries(
      notes.map((item, i) => [
        `event-${i}`,
        {
          drawTime: "afterDatasetsDraw",
          id: `event-${i}`,
          type: "line",
          scaleID: "x",
          value: item.timestamp.valueOf(),
          borderColor: "rgba(0, 0, 0, 0.25)",
          borderWidth: 2,
          borderDash: [2, 2],
          enter(ctx) {
            noteIdxToShow = i;
            ctx.chart.update();
          },
          leave(ctx) {
            noteIdxToShow = undefined;
            ctx.chart.update();
          },
          click() {
            setNoteToModalShow(item);
          },
          label: {
            position: "start",
            yAdjust: 5,
            font: {
              size: 10,
              family: "Arial",
              style: "normal",
              weight: "bold",
              lineHeight: 1.2,
            },
            color: "#555",
            drawTime: "afterDraw",
            enabled: true,
            backgroundColor: "white",
            borderColor: "#ddd",
            borderWidth: 1,
            content() {
              return noteIdxToShow === i ? ellipsise(item.note, 35) : "✏️";
            },
            // Note: the following properties don't work - z-index doesn't exist in the type, and the other two cause infinite loops :(

            // z(){
            //   return noteIdxToShow === i ? 1 : 0;
            // },
            // padding(){
            //   return noteIdxToShow !== i ? { top: 6, bottom: 4, left: 3, right: 3 } : 0;
            // },
            // borderRadius(){
            //   return noteIdxToShow !== i ? 3 : 0;
            // },
          },
        } satisfies AnnotationOptions,
      ])
    );
    return {
      ...options,
      responsive: true,
      scales: {
        ...options.scales,
        y: {
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "Run-off %",
          },
          beginAtZero: true,
          suggestedMax: 100,
        },
        y1: {
          type: "linear",
          display: true,
          position: "right",
          title: {
            display: true,
            text: "pH / EC",
          },
          beginAtZero: true,
          suggestedMax: 11,
          grid: {
            borderDash: [4, 10],
          },
        },
      },
      plugins: {
        annotation: { annotations: annotationSettings },
        zoom: {
          zoom: { drag: { enabled: true }, mode: "x" },
        },
      },
    };
  }, [chart.id, range, notes]);

  return (
    <>
      <Chart
        chartRef={chartEvents.chartRef}
        data={chartConfig}
        options={chartOptions}
      />
      <NotesModal
        modalOpen={!!noteToModalShow}
        setModalOpen={(value) =>
          setNoteToModalShow(value ? noteToModalShow : undefined)
        }
        data={noteToModalShow}
      />
    </>
  );
};

export default ChartAgronomics;

import {
  createTheme,
  lighten,
  darken,
  hexToRgb,
} from "@material-ui/core/styles";

const defaultTheme = createTheme();

const tints = (hex, intensity = 0.05) =>
  [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
    (a, b) => ({ ...a, ["tint_" + b * 100]: darken(hex, b * intensity) }),
    {}
  );

const theme = createTheme({
  palette: {
    primary: {
      light: hexToRgb("#13c2b4"),
      main: hexToRgb("#009287"),
      dark: darken("#009287", 0.5),
      ...tints("#009287"),
    },
    secondary: {
      light: lighten("#f8cf10", 0.5),
      main: hexToRgb("#f8cf10"),
      dark: darken("#f8cf10", 0.5),
      contrastText: "#ffcc00",
    },
    tertiary: {
      light: lighten("#536d7e", 0.5),
      main: hexToRgb("#536d7e"),
      dark: darken("#536d7e", 0.5),
      ...tints("#536d7e"),
    },
    warning: {
      light: lighten("#dc8c22", 0.5),
      main: hexToRgb("#dc8c22"),
      dark: darken("#dc8c22", 0.5),
      ...tints("#dc8c22"),
    },
    info: {
      light: lighten("#35acec", 0.5),
      main: hexToRgb("#35acec"),
      dark: darken("#35acec", 0.5),
      ...tints("#35acec"),
    },
    danger: {
      light: lighten("#d05144", 0.5),
      main: hexToRgb("#d05144"),
      dark: darken("#d05144", 0.5),
      ...tints("#d05144"),
    },
    pink: {
      light: lighten("#ec6acb", 0.5),
      main: hexToRgb("#ec6acb"),
      dark: darken("#ec6acb", 0.5),
      ...tints("#ec6acb"),
    },
    purple: {
      light: lighten("#8d4be0", 0.5),
      main: hexToRgb("#8d4be0"),
      dark: darken("#8d4be0", 0.5),
      ...tints("#8d4be0"),
    },
    orange: {
      light: lighten("#ff9000", 0.5),
      main: hexToRgb("#ff9000"),
      dark: darken("#ff9000", 0.5),
      ...tints("#ff9000"),
    },
    brown: {
      light: lighten("#a0735d", 0.5),
      main: hexToRgb("#a0735d"),
      dark: darken("#a0735d", 0.5),
      ...tints("#a0735d"),
    },
    black: {
      light: lighten("#000000", 0.5),
      main: hexToRgb("#000000"),
      dark: "#000000",
      ...tints("#dddddd"),
    },
    background: {
      default: defaultTheme.palette.grey[300],
    },
  },
  typography: {
    h1: { fontSize: "2.027rem", color: "#009287", fontWeight: 400 },
    h2: { fontSize: "1.802rem", color: "rgba(0, 0, 0, 0.54)", fontWeight: 400 },
    h3: { fontSize: "1.602rem", color: "#009287" },
    h4: {
      fontSize: "1.424rem",
      color: "#009287",
      fontWeight: 700,
      textTransform: "uppercase",
    },
    h5: { fontSize: "1.266rem", color: "#009287", fontWeight: 700 },
    h6: { fontSize: "1.125rem", fontWeight: 700 },
  },
});
export default theme;

import { useState, useEffect, useCallback, useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { useAuthState } from "../../../context/auth";
import {
  APIConfig,
  Client,
  getClient,
  saveClient,
  updateClient,
} from "../../../api/clients";
import Card from "../../cards/Card";
import { handleRequestError, triggerToast } from "../../../helpers";
import Loader from "../../elements/Loader";
import Headline from "../../elements/Headline";
import FormButton from "../../forms/FormButton";
import { useHistory } from "react-router-dom";
import { useStyles } from "./Admin";
import ClientForm, {
  ClientAccessKey,
  ClientFormValue,
} from "../../forms/admin/ClientForm";
import { listModules, ModuleInfo } from "../../../api/modules";

const toFormFreq = (freq?: APIConfig["syncFrequency"]) => {
  if (!freq) return;
  const [value, unit] = freq;
  return `${unit.slice(0, -1)}${value}` as ClientFormValue["syncFrequency"];
};

const freqMap: Record<
  Exclude<ClientFormValue["syncFrequency"], undefined | "disabled">,
  APIConfig["syncFrequency"]
> = {
  hour1: [1, "hours"],
  hour2: [2, "hours"],
  hour4: [4, "hours"],
  hour6: [6, "hours"],
  hour12: [12, "hours"],
  day1: [1, "days"],
};

const ManageClient = ({
  id,
  setIsLoading,
}: {
  id: number;
  setIsLoading: (newValue: boolean) => void;
}) => {
  const classes = useStyles();
  const user = useAuthState();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [client, setClient] = useState<Client>();
  const [permissions, setPermissions] = useState<ModuleInfo[]>();

  useEffect(() => {
    setIsLoading(loading);
  }, [setIsLoading, loading]);

  const loadClient = useCallback(async () => {
    if (!user.token) return;
    setLoading(true);
    try {
      const response = await getClient(user.token, id);
      setClient(response.data);
      setLoading(false);
    } catch (e) {
      handleRequestError(e, "Failed fetching client: ");
      setLoading(false);
    }
  }, [user.token, id]);

  const fetchPermissions = useCallback(async () => {
    if (!user.token) return;
    setLoading(true);
    try {
      const { data: modules } = await listModules(user.token);
      setPermissions(modules);
      setLoading(false);
      return modules;
    } catch (e) {
      setLoading(false);
      handleRequestError(e, "Failed fetching modules: ");
    }
  }, [user.token]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  // Load client initially
  useEffect(() => {
    id && loadClient();
  }, [loadClient, id]);

  const handleSubmit = async (formValues: ClientFormValue) => {
    if (!user.token) return;
    setWaiting(true);
    const { id, name, directory } = formValues;
    const modules = Object.keys(formValues).reduce((arr: number[], key) => {
      const accessKey = key.startsWith("access_")
        ? (key as ClientAccessKey)
        : null;
      if (accessKey && formValues[accessKey]) {
        arr.push(parseInt(accessKey.replace("access_", "")));
      }
      return arr;
    }, []);
    const apiConfig: APIConfig | undefined =
      formValues.api && formValues.syncFrequency
        ? {
            api: formValues.api,
            syncFrequency: freqMap[formValues.syncFrequency],
          }
        : undefined;

    try {
      const api_config = apiConfig ? JSON.stringify(apiConfig) : undefined;
      const values = { id, name, directory, modules, api_config };
      const response = id
        ? await updateClient(user.token, id, values)
        : await saveClient(user.token, values);
      dispatch(triggerToast(response.message, { variant: "success" }));
      setWaiting(false);
      history.push("/admin");
    } catch (e) {
      setWaiting(false);
      handleRequestError(e, `Failed to ${id ? "update" : "save"} client: `);
    }
  };

  const formInputs = useMemo<Partial<ClientFormValue> | undefined>(() => {
    const access =
      permissions &&
      client &&
      permissions.reduce((obj, item) => {
        obj[`access_${item.id}`] = client.module_access
          ?.map((item) => item.module_id)
          .includes(item.id);
        return obj;
      }, {} as Partial<Record<ClientAccessKey, boolean>>);

    const apiConfig = client?.api_config
      ? (JSON.parse(client.api_config) as APIConfig)
      : undefined;

    return client && access
      ? {
          id: client.id ?? undefined,
          name: client.name,
          directory: client.directory,
          api: apiConfig?.api,
          syncFrequency: toFormFreq(apiConfig?.syncFrequency),
          ...access,
        }
      : undefined;
  }, [client, permissions]);

  return (
    <Loader active={loading} showChildren={false} message={`Loading Client...`}>
      <Headline headline={`Edit Client`} headlineVariant="h1" divider={true}>
        <FormButton
          text={id ? "Update" : "Save"}
          disabled={!valid || waiting}
          handleClick={() => dispatch(submit("ClientForm"))}
          variant="contained"
          color="primary"
          waiting={waiting}
          waitingText={id ? "Updating" : "Saving"}
        />
      </Headline>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.subTitle}
              >
                DETAILS:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <ClientForm
                  permissions={permissions}
                  initialValues={formInputs}
                  isValid={(status) => setValid(status)}
                  isWaiting={(status) => setWaiting(status)}
                  onSubmit={handleSubmit}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default ManageClient;

import api, { DateRange } from "./agritech";

function normaliseAgronomicsKeys(
  formValues: Record<string, unknown>,
  addPrefix = false
) {
  return Object.keys(formValues).reduce((obj, key) => {
    if (addPrefix) {
      const exclusions = ["id", "field_id", "module_id", "notes", "timestamp"];
      obj[exclusions.includes(key) ? key : `ag_${key}`] = formValues[key];
    } else {
      obj[key.includes("ag_") ? key.replace("ag_", "") : key] = formValues[key];
    }
    return obj;
  }, {} as typeof formValues);
}

async function ListAgronomics(
  token: string,
  clientId: number,
  fieldId: number,
  range: DateRange
) {
  const response = await api.post(
    `${clientId}/agronomics/${fieldId}/search`,
    range,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

type Agronomics = object;
async function SaveAgronomics(
  token: string,
  clientId: number,
  fieldId: number,
  values: Agronomics
) {
  const response = await api.post(`${clientId}/agronomics/${fieldId}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

async function UpdateAgronomics(
  token: string,
  clientId: number,
  fieldId: number,
  id: number,
  values: Agronomics
) {
  const response = await api.patch(
    `${clientId}/agronomics/${fieldId}/${id}`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
}

async function DeleteAgronomics(
  token: string,
  clientId: number,
  fieldId: number,
  id: number
) {
  const response = await api.delete(`${clientId}/agronomics/${fieldId}/${id}`, {
    headers: { Authorization: "Bearer " + token },
  });
  return response.data;
}

async function ListAggregated(
  token: string,
  clientId: number,
  fieldId: number,
  range: DateRange,
  groups: object[]
) {
  const response = await api.get(`${clientId}/aggregated/${fieldId}/search`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { ...range, groups },
  });
  return response.data;
}

export {
  ListAgronomics,
  SaveAgronomics,
  UpdateAgronomics,
  DeleteAgronomics,
  normaliseAgronomicsKeys,
  ListAggregated,
};

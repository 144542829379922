import { Tooltip, Typography } from "@material-ui/core";
import { useMemo } from "react";
import Image from "../elements/Image";
import Statistic from "../elements/Statistic";
import Table from "../tables/Table";
import ChartGrowerMoisture from "../charts/ChartGrowerMoisture";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { useExtendedTheme } from "../../helpers/useExtendedTheme";
import { capitalizeFirstLetter as capitalise } from "../../helpers";

const gaugeRender = (
  row: { name: string; data: { value: number }[]; parameters: unknown },
  isSoil: boolean
) => {
  return (
    <ChartGrowerMoisture
      id={row.name}
      level={row.data[0].value}
      isSoil={isSoil}
      paramData={row.parameters}
    />
  );
};

const FieldsTable = ({
  fields,
  title,
  onClick,
  fieldType,
  clientId,
}: {
  fields: {
    id: number;
    name: string;
    data: Record<string, { short_name: string; ref: string }>;
  }[];
  title: string;
  onClick: (id: number) => void;
  fieldType: "soil" | "substrate";
  clientId: string;
}) => {
  const theme = useExtendedTheme();

  const columns = useMemo(() => {
    const userTableColumns =
      fields && fields.length && fields[0].data && fields[0].data.length
        ? Object.keys(fields[0].data).map((metric) => {
            return {
              title: fields[0].data[metric].short_name,
              field: fields[0].data[metric].ref,
              render: ({ data, parameters }: any) => {
                const { ref, value, suffix, thresholds, timestamp } =
                  data[metric];

                if (timestamp) {
                  return (
                    <Tooltip
                      title={timestamp.format("DD/MM/YYYY HH:mm")}
                      placement="top"
                    >
                      <span>
                        <Statistic
                          {...{
                            type: ref,
                            value,
                            suffix,
                            thresholds,
                            parameters,
                          }}
                        />
                      </span>
                    </Tooltip>
                  );
                }

                return (
                  <Statistic
                    {...{ type: ref, value, suffix, thresholds, parameters }}
                  />
                );
              },
            };
          })
        : [];

    const soilColumnOrder = [
      "moisture_content",
      "smd",
      "total_24hr_gains",
      "irri_trigger",
      "predicted_irri",
      "rainfall",
      "ambient_temp",
      "humidity",
    ];
    const orderedUserTableColumns =
      fieldType === "soil"
        ? soilColumnOrder.reduce((arr: typeof userTableColumns, ref) => {
            const col = userTableColumns.find(({ field }) => field === ref);
            return col ? [...arr, col] : arr;
          }, [])
        : userTableColumns;

    const defaultColumns = [
      {
        title: "Name",
        field: "name",
        cellStyle: {
          textTransform: "uppercase" as const,
          fontWeight: "bold" as const,
          color: theme.palette.tertiary.main,
        },
        render: ({ name }: { name: string }) => name,
      },
    ];

    const moistureGauge = [
      {
        title: "",
        align: "center" as const,
        field: "moistureguage",
        render: (row: any) => {
          if (row.irrimax) {
            return (
              <Image src={row.irrimax[0].gauge} alt={row.irrimax[0].name} />
            );
          }
          return (
            row.data?.length > 0 &&
            row.data[0]?.value > 0 &&
            gaugeRender(row, fieldType === "soil")
          );
        },
      },
    ];

    return fields && fields.length
      ? [...defaultColumns, ...moistureGauge, ...orderedUserTableColumns]
      : defaultColumns;
  }, [fieldType, fields, theme.palette.tertiary.main]);

  const [pageSize, setPageSize] = useLocalStorage(
    `${fieldType}FieldTablePageSize`,
    5,
    true
  );
  return (
    <Table
      columns={columns}
      data={fields}
      options={{
        pageSize,
        pageSizeOptions: [5, 10, 20, 50],
        headerStyle: { position: "sticky", top: 0 },
        maxBodyHeight: "800px",
      }}
      title={
        <Typography variant="h2" color="primary">
          {title}
        </Typography>
      }
      onRowClick={(event: any, row) => row && onClick(row.id)}
      onRowsPerPageChange={setPageSize}
      columnStorageKey={`${clientId}${capitalise(fieldType)}FieldTableColumns`}
    />
  );
};

export default FieldsTable;

import {
  createContext,
  useReducer,
  useContext,
  Dispatch,
  ReactNode,
} from "react";
import config from "../constants/charts";

export type BaseSetting = {
  id: string | number;
  client_id: string | number;
  field_id: string | number;
  type: "chart" | "field";
  order: number;
  hidden: 0 | 1;
  label: string;
  ref: null | keyof typeof config;
} & (
  | {
      type: "chart";
      ref: keyof typeof config;
    }
  | {
      type: "field";
      ref: null;
    }
);

type ChartSetting = BaseSetting & { type: "chart" };

type SettingsState = {
  fieldsSettings: {
    visibility: BaseSetting[];
  };
  chartsSettings: {
    visibility: ChartSetting[];
  };
};

const SettingsStateContext = createContext<SettingsState | undefined>(
  undefined
);

type SettingsAction =
  | {
      type: "fieldsVisibility";
      payload: SettingsState["fieldsSettings"]["visibility"];
    }
  | {
      type: "chartsVisibility";
      payload: SettingsState["chartsSettings"]["visibility"];
    }
  | { type: "unhandled" };
const SettingsDispatchContext = createContext<
  Dispatch<SettingsAction> | undefined
>(undefined);

function settingsReducer(state: SettingsState, action: SettingsAction) {
  switch (action.type) {
    case "fieldsVisibility": {
      return {
        ...state,
        fieldsSettings: { ...state.fieldsSettings, visibility: action.payload },
      };
    }
    case "chartsVisibility": {
      return {
        ...state,
        chartsSettings: { ...state.chartsSettings, visibility: action.payload },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SettingsProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(settingsReducer, {
    fieldsSettings: {
      visibility: [],
    },
    chartsSettings: {
      visibility: [],
    },
  });
  return (
    <SettingsStateContext.Provider value={state}>
      <SettingsDispatchContext.Provider value={dispatch}>
        {children}
      </SettingsDispatchContext.Provider>
    </SettingsStateContext.Provider>
  );
}

function useSettingsState() {
  const context = useContext(SettingsStateContext);
  if (context === undefined) {
    throw new Error("useSettingsState must be used within a SettingsProvider");
  }
  return context;
}

function useSettingsDispatch() {
  const context = useContext(SettingsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useSettingsDispatch must be used within a SettingsProvider"
    );
  }
  return context;
}

const filterAndOrderSettings = (
  clientId: string,
  settings: Record<string, BaseSetting>,
  type: "chart" | "field"
) =>
  Object.values(settings)
    .filter((item) => item.client_id === clientId && item.type === type)
    .sort((a, b) => (a.order > b.order ? 1 : -1));

function setVisibility(
  dispatch: Dispatch<SettingsAction>,
  clientId: string,
  settings: Record<string, BaseSetting>
) {
  dispatch({
    type: "fieldsVisibility",
    payload: filterAndOrderSettings(clientId, settings, "field"),
  });
  dispatch({
    type: "chartsVisibility",
    payload: filterAndOrderSettings(
      clientId,
      settings,
      "chart"
    ) as ChartSetting[],
  });
}

export {
  SettingsProvider,
  useSettingsState,
  useSettingsDispatch,
  setVisibility,
};

export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value != null;

export const filterEmpty = <T>(array: (T | null | undefined)[]): T[] =>
  array.filter(notEmpty);

/**
 * Combines map and filterEmpty
 *
 * @param fn Conversion function, return undefined to filter out
 */
export const refine = <I, O>(
  input: Iterable<I>,
  fn: (input: I, idx: number) => O | undefined
): O[] => {
  const arr = Array.isArray(input) ? input : [...input];
  return filterEmpty(arr.map(fn));
};
